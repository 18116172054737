import config from "./config";

import * as Backend from "@faintlines/backend-client";

import { createContext, useContext } from "react";
import { makeAutoObservable, autorun, toJS, runInAction } from "mobx";
import store from "store";
import { v4 as uuid } from "uuid";
import forEach from "lodash/forEach";

const PERSIST_STORE_KEY = "state";

// Store the login credentials and NOT the session id, since the session might be
// invalid by the next reload.
const PERSIST_KEYS = ["activePhoneId", "loginCreds"];

const STATE_SAVE_INTERVAL_MS = 2000;

export class Store {
    loggingIn = false;
    loginCreds = null;
    sessionId = null;

    connecting = false;
    activePhoneId = null;
    activePhone = null;
    connectionError = false;

    constructor() {
        const existingState = store.get(PERSIST_STORE_KEY);
        if (existingState) {
            PERSIST_KEYS.forEach((key) => {
                if (existingState[key]) {
                    this[key] = existingState[key];
                }
            });
        }

        makeAutoObservable(this);

        autorun(() => {
            const persistedState = {};
            PERSIST_KEYS.forEach(
                (key) => (persistedState[key] = toJS(this[key]))
            );
            store.set(PERSIST_STORE_KEY, persistedState);
        });

        if (config.login) {
            if (this.loginCreds) {
                this.login(this.loginCreds).then(() => {
                    if (this.activePhoneId) {
                        this.connectToPhone(this.activePhoneId);
                    }
                });
            } else {
                this.exitPhone();
            }
        } else if (this.activePhoneId) {
            this.connectToPhone(this.activePhoneId);
        }

        this._pendingPhoneStates = {};
        this._sendingStateToServer = {};

        this._tickers = [
            setInterval(
                () => this.sendPhoneStateToServer(),
                STATE_SAVE_INTERVAL_MS
            ),
        ];
    }

    login(creds) {
        if (this.loggingIn) {
            return Promise.reject();
        }

        this.loggingIn = true;
        return new Promise((resolve, reject) => {
            Backend.storyInteract({
                interactionType: "login",
                data: creds,
            })
                .then(({ data }) =>
                    runInAction(() => {
                        if (data.success) {
                            this.loginCreds = creds;
                            this.sessionId = data.session_id;
                            resolve({ data });
                        } else {
                            reject(data.error);
                        }
                    })
                )
                .catch(() => reject("Invalid token"))
                .finally(() =>
                    runInAction(() => {
                        this.loggingIn = false;
                    })
                );
        });
    }

    connectToPhone(phoneId) {
        if (this.connecting) {
            return;
        }

        this.connecting = true;
        this.connectionError = false;

        Backend.storyInteract({
            interactionType: "get_phone",
            sessionId: this.sessionId,
            data: {
                id: phoneId,
            },
        })
            .then(({ data }) =>
                runInAction(() => {
                    if (data.success) {
                        this.activePhone = data.phone;
                        this.activePhoneId = phoneId;
                        if (data.session_id) {
                            this.sessionId = data.session_id;
                        }
                    } else {
                        this.connectionError = true;
                    }
                })
            )
            .catch(() =>
                runInAction(() => {
                    this.connectionError = true;
                })
            )
            .finally(() =>
                runInAction(() => {
                    this.connecting = false;
                })
            );
    }

    exitPhone() {
        this.activePhoneId = null;
    }

    handleStateChange(state) {
        this._pendingPhoneStates[this.activePhoneId] = [state, uuid()];
    }

    sendPhoneStateToServer() {
        if (!config.phoneSim?.serverState) {
            return;
        }

        forEach(this._pendingPhoneStates, ([state, stateUuid], phoneId) => {
            if (this._sendingStateToServer[phoneId]) {
                return;
            }

            this._sendingStateToServer[phoneId] = true;

            Backend.storyInteract({
                interactionType: "phone_state",
                sessionId: this.sessionId,
                data: { phoneId, state },
            }).finally(() => {
                this._sendingStateToServer[phoneId] = false;

                const pendingUuid = (this._pendingPhoneStates[phoneId] ||
                    [])[1];
                if (pendingUuid === stateUuid) {
                    // if state changed since last sent, don't reset
                    delete this._pendingPhoneStates[phoneId];
                }
            });
        });
    }
}

export const StoreContext = createContext(null);
export function useStore() {
    return useContext(StoreContext);
}
