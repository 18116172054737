import styles from "./PhoneSimulatorWebsite.module.css";
import LoginPage from "./LoginPage";
import ConnectingPage from "./ConnectingPage";
import { useStore } from "../store";
import config from "../config.json";

import { PhoneSimulator } from "@faintlines/phone-simulator";
import "@faintlines/phone-simulator/dist/index.css";

import Widget from "@faintlines/widget";
import "@faintlines/widget/dist/index.css";

import "@fontsource/roboto/400.css";
import "@fontsource/roboto/700.css";

import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import classnames from "classnames";
import { useMediaQuery } from "react-responsive";
import React from "react";

export default function PhoneSimulatorWebsite() {
    return (
        <div className={classnames(styles.app, config.site.theme)}>
            <SiteContent />
            {config.widget ? <WidgetWrapper /> : null}
        </div>
    );
}

const SiteContent = observer(() => {
    const store = useStore();
    const { activePhoneId, activePhone, connecting } = store;
    const isBigScreen = useMediaQuery({ query: "(min-width: 600px)" });

    if (!activePhoneId || !activePhone) {
        if (connecting) {
            return <ConnectingPage />;
        }
        return <LoginPage />;
    }

    const phoneWidth = activePhone?.settings?.width || 360;
    const phoneHeight = activePhone?.settings?.height || 600;

    const size = {
        width: isBigScreen ? phoneWidth : "100%",
        height: isBigScreen ? phoneHeight : "100%",
        maxHeight: isBigScreen ? "95%" : null,
        minHeight: isBigScreen ? 400 : null,
    };

    return (
        <div className={styles.phoneWrapper}>
            <div className={styles.phoneWrapperContents} style={size}>
                <PhoneSimulator
                    story={toJS(activePhone)}
                    config={{
                        persistenceKeyPrefix: `phones__${config.story.id}__${config.story.assetId}`,
                        onStateChange: (state) =>
                            store.handleStateChange(state),
                    }}
                    onExit={() => store.exitPhone()}
                />
            </div>
        </div>
    );
});

const WidgetWrapper = observer(() => {
    const store = useStore();
    return (
        <Widget
            config={config.widget}
            storyConfig={config.story}
            context={{
                loggedIn: !!store.sessionId,
                activePhoneId: store.activePhoneId,
            }}
        />
    );
});
